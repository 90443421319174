import { defineStore } from 'pinia';

export const useGeneralStore = defineStore('general', () => {
  const isCartSidebarOpen = ref(false);
  const isSearchBarOpen = ref(false);
  const isWishlistSidebarOpen = ref(false);
  const isLoginModalOpen = ref(false);
  const isNewsletterModalOpen = ref(false);
  const isCategoryGridView = ref(true);
  const isFilterSidebarOpen = ref(false);
  const isMobileMenuOpen = ref(false);

  const toggleMobileMenu = () => {
    isMobileMenuOpen.value = !isMobileMenuOpen.value;
  };

  const toggleCartSidebar = () => {
    if (isMobileMenuOpen.value) toggleMobileMenu();
    isCartSidebarOpen.value = !isCartSidebarOpen.value;
  };

  const toggleWishlistSidebar = () => {
    if (isMobileMenuOpen.value) toggleMobileMenu();
    isWishlistSidebarOpen.value = !isWishlistSidebarOpen.value;
  };

  const toggleLoginModal = () => {
    if (isMobileMenuOpen.value) toggleMobileMenu();
    isLoginModalOpen.value = !isLoginModalOpen.value;
  };

  const toggleNewsletterModal = () => {
    isNewsletterModalOpen.value = !isNewsletterModalOpen.value;
  };

  const changeToCategoryGridView = () => {
    isCategoryGridView.value = true;
  };
  const changeToCategoryListView = () => {
    isCategoryGridView.value = false;
  };

  const toggleFilterSidebar = () => {
    isFilterSidebarOpen.value = !isFilterSidebarOpen.value;
  };

  const toggleSearchBar = () => {
    isSearchBarOpen.value = !isSearchBarOpen.value;
  };

  return {
    isCartSidebarOpen,
    isSearchBarOpen,
    isWishlistSidebarOpen,
    isLoginModalOpen,
    isNewsletterModalOpen,
    isCategoryGridView,
    isFilterSidebarOpen,
    isMobileMenuOpen,
    toggleSearchBar,
    toggleCartSidebar,
    toggleWishlistSidebar,
    toggleLoginModal,
    toggleNewsletterModal,
    changeToCategoryGridView,
    changeToCategoryListView,
    toggleFilterSidebar,
    toggleMobileMenu,
  };
});
